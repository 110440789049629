<template>
    <helpMenu></helpMenu>
</template>
<script>
import helpMenu from "@/components/help/index.vue";

export default {
    name: "",
    components:{
        helpMenu
    },
    data(){
        return{

        };
    }
}
</script>
