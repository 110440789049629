<template>
  <div class="help-main">
    <div class="left-menu">
      <ul
        :class="['left-menu-item',{'display':item.leftDisplay}]"
        v-for="(item, index) in $helpMenus.helpMenus"
        :key="index"
      >
        <span class="menu-span">{{ item.title }}</span>
        <li
          v-for="(items, indexs) in item.menusItem"
          :key="indexs"
          :class="['menu', { on: items.id == menuId }]"
        >
          <a v-if="items.linkUrl" :href="items.linkUrl"></a>
          <span v-else class="title" @click="init(items.id, index)">{{
            items.name
          }}</span>
        </li>
      </ul>
    </div>
    <div class="right">
      <div class="title">{{ getTitle }}</div>
      <div class="box" v-html="getHtml"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      menuId: 1,
      menuIndex: 0,
      rightHtml: "",
    };
  },
  watch: {
    $route(e) {
      const query = e.query;
      if (query) {
        this.menuId = query.id;
        this.menuIndex = query.index;
      }
    },
  },
  activated() {
    this.menuId = 1;
    this.menuIndex = 0;
    // this.init();
    const query = this.$route.query;
    if (query) {
      this.menuId = query.id;
      this.menuIndex = query.index;
    }
  },
  computed: {
    getTitle() {
      let title = "";
      const val = this.$helpMenus.helpMenus[this.menuIndex].menusItem.find(
        (item) => item.id == this.menuId
      );
      if (val) {
        title = val.name;
      }
      return title;
    },
    getHtml() {
      let html = "";
      const val = this.$helpMenus.helpMenus[this.menuIndex].menusItem.find(
        (item) => item.id == this.menuId
      );
      if (val) {
        html = val.html.replace(new RegExp('imgUrl','g'),this.$imgUrl) ;
      }
      return html;
    },
  },
  methods: {
    init(id, index) {
      this.$router.push({
        path: "/help",
        query: { id: id, index: index },
      });
    },
  },
};
</script>
<style lang="scss">
.help-main {
  width: 1224px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 25px;

  .left-menu {
    width: 160px;
    float: left;
    margin-left: 100px;
    color: #818181;

    .left-menu-item {
      margin-bottom: 30px;
      .menu-span {
        color: var(--ThemeColor,#ff6701);
        font-size: 16px; /* font-family: "微软雅黑"; */
        margin-bottom: 5px;
        font-weight: normal;
        font-weight: 600;
      }
      .menu {
        cursor: pointer;       
        &.on {
          text-decoration: underline;    
          color: var(--ThemeColor,#ff6701);     
        }
      }
      &.display{
        display: none;
      }
    }
  }

  .right {
    width: 980px;
    padding-left: 300px;
    .title {
      /* font-family: "微软雅黑"; */
      font-size: 16px;
      color: var(--ThemeColor,#ff6701);
      font-weight: bold;
      padding-bottom: 20px;
    }
  }
}
</style>
